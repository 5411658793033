@font-face {
	font-family: 'Matter';
	src: url('./fonts/Matter-Regular.woff') format('woff'), url('./fonts/Matter-Regular.woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Matter-Bold';
	src: url('./fonts/Matter-Bold.woff') format('woff'), url('./fonts/Matter-Bold.woff2');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

table {
	width: 100%
}
.sv-body__page {
	margin-right: 0;
	margin-left: 0;
}
.sv-title {
	font-family: 'Matter';
	font-size: 16px;
	font-weight: 700;
	color: #253746;
  letter-spacing: 0.15px;
  line-height: 22px;

}
@media (min-width: 1200px) {
  .sv-title__customrating {
    width: calc((100vw * 37 / 40 - 950px)) !important;
  }
}
@media (min-width: 1500px) {
  .adjust-rating-padding {
    padding-right: calc(100vw * 41 / 85 - 675px) !important;
  }
}

.noUi-connect, .sv-root-modern ::-webkit-scrollbar-thumb {
	background: #006Fb7 !important;
}
.sv-root-modern .sv-text:focus,
.sv-root-modern .sv-item__control:focus + .sv-item__decorator,
.sv-root-modern .sv-comment:focus {
	border-color: #006Fb7 !important;
  border-width: 2px;
}


.sv-root-modern .sv-question__title--answer {
	background-color: #FFF !important;
}
.sv-root-modern .sv-radio--checked .sv-radio__svg {
	border-color: #006FB7 !important;
	fill: #006FB7 !important;
}
.sv-root-modern .sv-checkbox--checked .sv-checkbox__svg {
	background-color: #006FB7 !important;
	fill: #FFF !important;
}
.sv-root-modern .sv-rating__item--selected .sv-rating__item-text {
		background-color: #006FB7 !important;
    color: #FFF !important;
    border-color: #006FB7 !important;
}
.sv-root-modern .sv-rating input:focus + .sv-rating__min-text + .sv-rating__item-text, .sv-rating input:focus + .sv-rating__item-text {
	outline-color: #006FB7 !important;
}
.sv-multipletext__cell:not(:last-child) {
	width: 10%;
	vertical-align: bottom;
}
.sv-table td:first-child, .sv-table th:first-child {
	width: 50%;
}
.sv-dropdown {
	background-image: url("data:image/svg+xml,<%3Fxml version='1.0' encoding='utf-8'%3F><!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version='1.1' id='Layer_1' aria-hidden='true' role='presentation' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'><style type='text/css'> .st0%7Bfill:%23040404;%7D%0A</style><polygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/></svg>%0A");
}
.sv-dropdown:focus {
	background-image: url("data:image/svg+xml,<%3Fxml version='1.0' encoding='utf-8'%3F><!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version='1.1' id='Layer_1' aria-hidden='true' role='presentation' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'><style type='text/css'> .st0%7Bfill:%23006fb7;%7D%0A</style><polygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/></svg>%0A");
  border-width: 2px;
  border-color: #006Fb7 !important;
}
.sv-question__header--location--left {
  float: left;
  width: 40%;
  margin-right: 0.875em;
}

.sv-unanswered .sv-question__header h5{
  color: #B00020 !important;
}
/* .sv-row:not(:last-child) {
	padding-bottom: 1em;
} */
/*
.sv-matrix .sv-table__cell--header {
	font-weight: normal !important;
	padding: 12px 6px !important;
}
.sv-matrix {
	overflow: hidden;
}
.sv-row .sv-matrix .sv-table {
	background-color: #EEEFF1;
	height: 48px;
	padding-top: 6px;
}
.sv-row:nth-child(even) .sv-matrix .sv-table {
	background-color: transparent;
	height: 42px;
	padding-top: 0;
	margin-bottom: -6px;
}
.sv-row:first-child .sv-table {
	margin-top: -6px;
}
.sv-row:first-child .sv-matrix table thead {
	background-color: white !important;
}
.sv-row:not(:first-child) .sv-matrix table thead {
	display: none !important;
}
.sv-row:nth-child(even) .sv-matrix .sv-table tbody sv-table__cell {
	padding-top: 6px;
}
.sv-matrix .sv-table th {
	width: 150px;
}
.sv-matrix .sv-table td:not(:first-child) {
	width: 150px;
}

.sv-table td:first-child, .sv-table th:first-child {
	padding-left: 12px;
}
.sv-table tbody tr:last-child .sv-table__cell {
	padding-bottom: 6px;
}
.sv-table tr:first-child .sv-table__cell {
	padding-top: 6px;
}
*/

@keyframes ldio-78fhm4xqnpp {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5,1.5);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.ldio-78fhm4xqnpp div > div {
  position: absolute;
  width: 13.68px;
  height: 13.68px;
  border-radius: 50%;
  background: #006fb7;
  animation: ldio-78fhm4xqnpp 1s linear infinite;
}.ldio-78fhm4xqnpp div:nth-child(1) > div {
  left: 84px;
  top: 49.99999999999999px;
  animation-delay: -0.875s;
}
.ldio-78fhm4xqnpp > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 90.84px 56.839999999999996px;
}.ldio-78fhm4xqnpp div:nth-child(2) > div {
  left: 74px;
  top: 74px;
  animation-delay: -0.75s;
}
.ldio-78fhm4xqnpp > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 80.84px 80.84px;
}.ldio-78fhm4xqnpp div:nth-child(3) > div {
  left: 49.99999999999999px;
  top: 84px;
  animation-delay: -0.625s;
}
.ldio-78fhm4xqnpp > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 56.839999999999996px 90.84px;
}.ldio-78fhm4xqnpp div:nth-child(4) > div {
  left: 25.999999999999993px;
  top: 74px;
  animation-delay: -0.5s;
}
.ldio-78fhm4xqnpp > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 32.839999999999996px 80.84px;
}.ldio-78fhm4xqnpp div:nth-child(5) > div {
  left: 15.999999999999993px;
  top: 49.99999999999999px;
  animation-delay: -0.375s;
}
.ldio-78fhm4xqnpp > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 22.839999999999996px 56.839999999999996px;
}.ldio-78fhm4xqnpp div:nth-child(6) > div {
  left: 25.999999999999993px;
  top: 25.999999999999993px;
  animation-delay: -0.25s;
}
.ldio-78fhm4xqnpp > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 32.839999999999996px 32.839999999999996px;
}.ldio-78fhm4xqnpp div:nth-child(7) > div {
  left: 49.99999999999999px;
  top: 15.999999999999993px;
  animation-delay: -0.125s;
}
.ldio-78fhm4xqnpp > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 56.839999999999996px 22.839999999999996px;
}.ldio-78fhm4xqnpp div:nth-child(8) > div {
  left: 74px;
  top: 25.999999999999993px;
  animation-delay: 0s;
}
.ldio-78fhm4xqnpp > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 80.84px 32.839999999999996px;
}
.loadingio-spinner-spin-k1belx2d7a {
  width: 114px;
  height: 114px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-78fhm4xqnpp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-78fhm4xqnpp div { box-sizing: content-box; }
/* generated by https://loading.io/ */
